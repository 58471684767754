/**
 * Known types of content in Contentful
 */
const contentfulContentTypes = {
  Entry: 'ContentfulEntry',
  Asset: 'ContentfulAsset',
  /* pages */
  Page: 'ContentfulPage',
  LandingPage: 'ContentfulLandingPage',
  AdvancedPage: 'ContentfulAdvancedPage',
  ArticlePage: 'ContentfulArticlePage',
  /* navs */
  Navigation: 'ContentfulNavigation',
  NavigationElement: 'ContentfulNavigationElement',
  /* modules */
  HeroImageModule: 'ContentfulHeroImageModule',
  LeadTextModule: 'ContentfulLeadTextModule',
  LinkBlockModule: 'ContentfulLinkBlockModule',
  ImageModule: 'ContentfulImageModule',
  AccordionModule: 'ContentfulAccordionModule',
  VideoModule: 'ContentfulVideoModule',
  IFrameModule: 'ContentfulIFrameModule',
  MediaGalleryModule: 'ContentfulMediaGalleryModule',
  SocialMediaElementModule: 'ContentfulSocialMediaElement',
  FeedReaderWidgetModule: 'ContentfulFeedReaderWidgetModule',
  LatestArticlesModule: 'ContentfulLatestArticlesModule',
  ContactPersonModule: 'ContentfulContactPersonModule',
  ButtonLinkModule: 'ContentfulButtonLinkModule',
  FormModule: 'ContentfulFormModule',
  ContentListModule: 'ContentfulContentListModule',
  TableModule: 'ContentfulTableModule',
  SearchModule: 'ContentfulSearchModule',
  /* other */
  TextBlock: 'ContentfulTextBlock',
  Footer: 'ContentfulFooter',
  Category: 'ContentfulCategory',
};

const iframeTypes = {
  InvestisFullWidth: 'investisFullWidth',
  InvestisWidget: 'investisWidget',
  CisionFullWidth: 'cisionFullWidth',
  CisionWidget: 'cisionWidget',
};

const formTypes = {
  CisionSubscription: 'cisionSubscription',
};

const secondaryThemeTypes = {
  AnoraPurple: 'anoraPurple',
  AnoraYellow: 'anoraYellow',
  AnoraDarkBlue: 'anoraDarkBlue',
  AnoraWhite: 'anoraWhite',
  AnoraLightBlue: 'anoraLightBlue',
  AnoraGreen: 'anoraGreen',
  AnoraTransparent: 'anoraTransparent',
};

const searchTypes = {
  AnoraGroupGeneralSearch: 'anoraGroupGeneralSearch',
};

const messageTypes = {
  Success: 'Success',
  Warning: 'Warning',
  Error: 'Error',
  Info: 'Info',
};

const siteMaintenanceMode = {
  ActiveMode: ['Activate Maintenance Mode'],
};

const careersFeeds = {
  careersFeedFI: '/paikat/?o=A_RSS&jgid=1',
  arcusFeedNO: '/Public/rssfeed/external.ashx?id=6&InstallationID=I008&CustomerName=arcus&lang=NO',
};

module.exports = {
  contentfulContentTypes,
  iframeTypes,
  messageTypes,
  secondaryThemeTypes,
  formTypes,
  searchTypes,
  siteMaintenanceMode,
  careersFeeds,
};
